<template>
  <div>
    <center>
      <h1>Binnenkort beschikbaar</h1>
    </center>
  </div>
</template>
<script>
export default {
  name: "503",
  components: {},
};
</script>
